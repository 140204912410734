import { AbpModule } from "@abp/abp.module";
import * as ngCommon from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
import { ChatSignalrService } from "@app/shared/layout/chat/chat-signalr.service";
import { LinkAccountModalComponent } from "@app/shared/layout/link-account-modal.component";
import { LinkedAccountsModalComponent } from "@app/shared/layout/linked-accounts-modal.component";
import { LoginAttemptsModalComponent } from "@app/shared/layout/login-attempts-modal.component";
import { ChangePasswordModalComponent } from "@app/shared/layout/profile/change-password-modal.component";
import { ChangeProfilePictureModalComponent } from "@app/shared/layout/profile/change-profile-picture-modal.component";
import { MySettingsModalComponent } from "@app/shared/layout/profile/my-settings-modal.component";
import { SmsVerificationModalComponent } from "@app/shared/layout/profile/sms-verification-modal.component";
import { ServiceProxyModule } from "@shared/service-proxies/service-proxy.module";
import { UtilsModule } from "@shared/utils/utils.module";
//import { FileUploadModule, FileSelectDirective, FileUploader } from 'ng2-file-upload';
import {
    FileUploadModule,
    FileDropDirective,
    FileSelectDirective,
} from "ng2-file-upload";
import {
    ModalModule,
    TabsModule,
    TooltipModule,
    BsDropdownModule,
    PopoverModule,
    BsDatepickerModule,
    TypeaheadModule,
    BsDaterangepickerConfig,
    BsLocaleService,
    BsDatepickerConfig,
} from "ngx-bootstrap";
import { FileUploadModule as PrimeNgFileUploadModule } from "primeng/fileupload";
import { PaginatorModule } from "primeng/paginator";
import { ProgressBarModule } from "primeng/progressbar";
import { TableModule } from "primeng/table";
import { ImpersonationService } from "./admin/users/impersonation.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DefaultLayoutComponent } from "./shared/layout/themes/default/default-layout.component";
import { Theme2LayoutComponent } from "./shared/layout/themes/theme2/theme2-layout.component";
import { Theme3LayoutComponent } from "./shared/layout/themes/theme3/theme3-layout.component";
import { Theme4LayoutComponent } from "./shared/layout/themes/theme4/theme4-layout.component";
import { Theme5LayoutComponent } from "./shared/layout/themes/theme5/theme5-layout.component";
import { Theme6LayoutComponent } from "./shared/layout/themes/theme6/theme6-layout.component";
import { Theme9LayoutComponent } from "./shared/layout/themes/theme9/theme9-layout.component";
import { Theme7LayoutComponent } from "./shared/layout/themes/theme7/theme7-layout.component";
import { Theme8LayoutComponent } from "./shared/layout/themes/theme8/theme8-layout.component";
import { Theme10LayoutComponent } from "./shared/layout/themes/theme10/theme10-layout.component";
import { Theme11LayoutComponent } from "./shared/layout/themes/theme11/theme11-layout.component";
import { Theme12LayoutComponent } from "./shared/layout/themes/theme12/theme12-layout.component";
import { AppCommonModule } from "./shared/common/app-common.module";
import { ChatBarComponent } from "./shared/layout/chat/chat-bar.component";
import { ThemeSelectionPanelComponent } from "./shared/layout/theme-selection/theme-selection-panel.component";
import { ChatFriendListItemComponent } from "./shared/layout/chat/chat-friend-list-item.component";
import { ChatMessageComponent } from "./shared/layout/chat/chat-message.component";
import { FooterComponent } from "./shared/layout/footer.component";
import { StackedFooterComponent } from "./shared/layout/stacked-footer.component";
import { LinkedAccountService } from "./shared/layout/linked-account.service";
import { SideBarMenuComponent } from "./shared/layout/nav/side-bar-menu.component";
import { TopBarMenuComponent } from "./shared/layout/nav/top-bar-menu.component";
import { TopBarComponent } from "./shared/layout/topbar.component";
import { DefaultBrandComponent } from "./shared/layout/themes/default/default-brand.component";
import { Theme2BrandComponent } from "./shared/layout/themes/theme2/theme2-brand.component";
import { Theme3BrandComponent } from "./shared/layout/themes/theme3/theme3-brand.component";
import { Theme4BrandComponent } from "./shared/layout/themes/theme4/theme4-brand.component";
import { Theme5BrandComponent } from "./shared/layout/themes/theme5/theme5-brand.component";
import { Theme6BrandComponent } from "./shared/layout/themes/theme6/theme6-brand.component";
import { Theme7BrandComponent } from "./shared/layout/themes/theme7/theme7-brand.component";
import { Theme8BrandComponent } from "./shared/layout/themes/theme8/theme8-brand.component";
import { Theme9BrandComponent } from "./shared/layout/themes/theme9/theme9-brand.component";
import { Theme10BrandComponent } from "./shared/layout/themes/theme10/theme10-brand.component";
import { Theme11BrandComponent } from "./shared/layout/themes/theme11/theme11-brand.component";
import { Theme12BrandComponent } from "./shared/layout/themes/theme12/theme12-brand.component";
import { UserNotificationHelper } from "./shared/layout/notifications/UserNotificationHelper";
import { HeaderNotificationsComponent } from "./shared/layout/notifications/header-notifications.component";
import { NotificationSettingsModalComponent } from "./shared/layout/notifications/notification-settings-modal.component";
import { NotificationsComponent } from "./shared/layout/notifications/notifications.component";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { TextMaskModule } from "angular2-text-mask";
import { ImageCropperModule } from "ngx-image-cropper";
import { SidebarModule } from "primeng/sidebar";
import { TreeViewModule } from "@syncfusion/ej2-angular-navigations";
import {
    PdfViewerModule,
    LinkAnnotationService,
    BookmarkViewService,
    MagnificationService,
    ThumbnailViewService,
    ToolbarService,
    NavigationService,
    TextSearchService,
    TextSelectionService,
    PrintService,
} from "@syncfusion/ej2-angular-pdfviewer";
import { ToolbarModule } from "@syncfusion/ej2-angular-navigations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// Metronic
import {
    PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    // suppressScrollX: true
};
import { defineLocale } from "ngx-bootstrap/chronos";
import { enGbLocale } from "ngx-bootstrap/locale";

defineLocale("engb", enGbLocale);

import { CoreModule } from "@metronic/app/core/core.module";
import { NgxBootstrapDatePickerConfigService } from "../assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service";
import { DeactivateGuard } from "./shared/deactivate-guard";
import { BlockUIModule } from "primeng/blockui";
import { SipYouthUploadComponent } from "./client/SipYouthUpload/sip-youth-upload.component";

//import { CreateEditServiceNotesComponent } from '../app/shared/common/serviceNotes/create-edit-service-notes.component';
//import { AutoCompleteModule } from 'primeng/autocomplete';
//import { DateTimePickerModule } from "@syncfusion/ej2-angular-calendars";
//import { DialogModule } from 'primeng/dialog';
//import { TooltipModule as NgToolTip } from 'primeng/tooltip';
//import { MaterialModule } from './shared/material-module';
@NgModule({
    declarations: [
        AppComponent,
        DefaultLayoutComponent,
        Theme2LayoutComponent,
        Theme3LayoutComponent,
        Theme4LayoutComponent,
        Theme5LayoutComponent,
        Theme6LayoutComponent,
        Theme7LayoutComponent,
        Theme8LayoutComponent,
        Theme9LayoutComponent,
        Theme10LayoutComponent,
        Theme11LayoutComponent,
        Theme12LayoutComponent,
        HeaderNotificationsComponent,
        SideBarMenuComponent,
        TopBarMenuComponent,
        FooterComponent,
        StackedFooterComponent,
        LoginAttemptsModalComponent,
        LinkedAccountsModalComponent,
        LinkAccountModalComponent,
        ChangePasswordModalComponent,
        ChangeProfilePictureModalComponent,
        MySettingsModalComponent,
        SmsVerificationModalComponent,
        NotificationsComponent,
        ChatBarComponent,
        ThemeSelectionPanelComponent,
        ChatFriendListItemComponent,
        NotificationSettingsModalComponent,
        ChatMessageComponent,
        TopBarComponent,
        DefaultBrandComponent,
        Theme2BrandComponent,
        Theme3BrandComponent,
        Theme4BrandComponent,
        Theme5BrandComponent,
        Theme6BrandComponent,
        Theme7BrandComponent,
        Theme8BrandComponent,
        Theme9BrandComponent,
        Theme10BrandComponent,
        Theme11BrandComponent,
        Theme12BrandComponent,
    ],
    imports: [
        //MaterialModule,
        //NgToolTip,
        //DialogModule,
        //DateTimePickerModule,
        //AutoCompleteModule,
        FileUploadModule,
        TreeViewModule,
        ToolbarModule,
        NgbModule,
        PdfViewerModule,
        ngCommon.CommonModule,
        FormsModule,
        HttpClientModule,
        BlockUIModule,
        HttpClientJsonpModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        BsDatepickerModule.forRoot(),
        FileUploadModule,
        AbpModule,
        AppRoutingModule,
        UtilsModule,
        AppCommonModule.forRoot(),
        ServiceProxyModule,
        TableModule,
        PaginatorModule,
        PrimeNgFileUploadModule,
        ProgressBarModule,
        PerfectScrollbarModule,
        CoreModule,
        NgxChartsModule,
        TextMaskModule,
        ImageCropperModule,

        SidebarModule,
        TypeaheadModule.forRoot(),
    ],
    providers: [
        ImpersonationService,
        LinkedAccountService,
        UserNotificationHelper,

        ChatSignalrService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        {
            provide: BsDatepickerConfig,
            useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig,
        },
        {
            provide: BsDaterangepickerConfig,
            useFactory:
                NgxBootstrapDatePickerConfigService.getDaterangepickerConfig,
        },
        {
            provide: BsLocaleService,
            useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale,
        },
        FileSelectDirective,
        FileDropDirective,
        DeactivateGuard,
        LinkAnnotationService,
        BookmarkViewService,
        MagnificationService,
        ThumbnailViewService,
        ToolbarService,
        NavigationService,
        TextSearchService,
        TextSelectionService,
        PrintService,
    ],
})
export class AppModule {}
