import { PermissionCheckerService } from "@abp/auth/permission-checker.service";
import { AppSessionService } from "@shared/common/session/app-session.service";

import { Injectable } from "@angular/core";
import { AppMenu } from "./app-menu";
import { AppMenuItem } from "./app-menu-item";

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {}

    getMenu(): AppMenu {
        return new AppMenu("MainMenu", "MainMenu", [
            new AppMenuItem(
                "Dashboard",
                "Pages.Administration.Host.Dashboard",
                "flaticon-line-graph",
                "/app/admin/hostDashboard"
            ),

            new AppMenuItem(
                "Dashboard",
                "Pages.Tenant.Dashboard",
                "fas fa-th-large",
                "/app/main/dashboard"
            ),
            new AppMenuItem(
                "Tenants",
                "Pages.Tenants",
                "flaticon-list-3",
                "/app/admin/tenants"
            ),

            //new AppMenuItem('COVID-19 Screening', '', 'fa fa-thermometer-half', '', [
            //    new AppMenuItem('Screenings', 'Pages.CovidScreenings', 'fa fa-search', '/app/main/covidScreenings/covidScreenings'),
            //    new AppMenuItem('Take Screening', 'Pages.CovidScreenings.Create', 'flaticon2-add-1', '/app/main/covidScreenings/covidScreenings/createOrEdit/0/txt'),
            //]),

            //new AppMenuItem('Reports', '', 'la la-book', '', [
            //    new AppMenuItem('COVID-19', 'Pages.CovidScreenings.Reportdownload', 'fa fa-thermometer-half', '/app/main/covidScreenings/covidScreenings/view'),
            //]),

            new AppMenuItem(
                "LTSS",
                "Pages.LTSSBilling",
                "fas fa-landmark",
                "",
                [
                    new AppMenuItem(
                        "LTSS Billing",
                        "Pages.LTSSBilling",
                        "fas fa-dollar-sign",
                        "/app/admin/ltss/upload"
                    ),
                    new AppMenuItem(
                        "LTSS Authorization",
                        "Pages.LTSSBilling",
                        "far fa-file-excel",
                        "/app/admin/ltss/services/upload"
                    ),
                    new AppMenuItem(
                        "Res Attendance",
                        "Pages.ResAttendance",
                        "fas fa-home",
                        "/app/admin/ltss/res-attendance"
                    ),
                ]
            ),

            new AppMenuItem("Facility Report", "", "fa fa-building ", "", [
                new AppMenuItem(
                    "Search",
                    "Pages.FacilityReports",
                    "fa fa-search",
                    "/app/facilityReports/facilityReports/facilityReports"
                ),
                new AppMenuItem(
                    "Create",
                    "Pages.FacilityReports.Create",
                    "flaticon2-add-1",
                    "/app/facilityReports/facilityReports/create-or-edit-facilityReport-modal/0/txt"
                ),
            ]),
            new AppMenuItem("Time Clock", "", "la la-ticket", "", [
                new AppMenuItem(
                    "Search",
                    "Pages.RequestTickets",
                    "fa fa-search",
                    "/app/helpdesk/requestTickets/requestTickets/timeclock"
                ),
                new AppMenuItem(
                    "Create",
                    "Pages.RequestTickets.Create",
                    "flaticon2-add-1",
                    "/app/helpdesk/requestTickets/create-or-edit-requestTicket-modal/0/timeclock"
                ),
            ]),
            new AppMenuItem("IT Helpdesk", "", "la la-ticket", "", [
                new AppMenuItem(
                    "Search",
                    "Pages.RequestTickets",
                    "fa fa-search",
                    "/app/helpdesk/requestTickets/requestTickets/helpdesk"
                ),
                new AppMenuItem(
                    "Create",
                    "Pages.RequestTickets.Create",
                    "flaticon2-add-1",
                    "/app/helpdesk/requestTickets/create-or-edit-requestTicket-modal/0/helpdesk"
                ),
            ]),

            new AppMenuItem("Client Profile", "", "fas fa-user-tie", "", [
                new AppMenuItem(
                    "Search",
                    "Pages.Patients",
                    "fa fa-search",
                    "/app/client/patients/patients"
                ),
                new AppMenuItem(
                    "Create",
                    "Pages.Patients.Create",
                    "flaticon2-add-1",
                    "/app/client/patients/create-or-edit-patients-modal/0/txt"
                ),
            ]),

            new AppMenuItem("Service Notes V2", "", "far fa-sticky-note", "", [
                new AppMenuItem(
                    "Search",
                    "Pages.ServiceNotes",
                    "fa fa-search",
                    "/app/client/services-notes/list"
                ),
                new AppMenuItem(
                    "Create",
                    "Pages.ServiceNotes.Create",
                    "flaticon2-add-1",
                    "/app/client/services-note/V2/0/new"
                ),
                new AppMenuItem(
                    "Res Attendance",
                    "Pages.ResAttendance",
                    "fas fa-home",
                    "/app/admin/ltss/res-attendance"
                ),
                new AppMenuItem(
                    "Service Notes Reports",
                    "Pages.ResAttendance.Reports",
                    "far fa-chart-bar",
                    "/app/admin/ltss/res-note-analytics"
                ),
            ]),

            new AppMenuItem("Client Diary", "", "fa fa-address-card", "", [
                new AppMenuItem(
                    "Search",
                    "Pages.ClientDiaries",
                    "fa fa-search",
                    "/app/client/clientDiaries/clientDiaries"
                ),
                new AppMenuItem(
                    "Create",
                    "Pages.ClientDiaries.Create",
                    "flaticon2-add-1",
                    "/app/client/clientDiaries/create-or-edit-clientDiary-modal/0/txt"
                ),
            ]),

            new AppMenuItem("Weight Charts", "", "fas fa-weight", "", [
                new AppMenuItem(
                    "Search",
                    "",
                    "fa fa-search",
                    "/app/client/weight-chart/list"
                ),
                new AppMenuItem(
                    "Create",
                    "",
                    "flaticon2-add-1",
                    "/app/client/weight-chart/0/new"
                ),
            ]),

            new AppMenuItem(
                "Sleep Tracking",
                "Pages.SleepCharts",
                "fas fa-bed",
                "",
                [
                    new AppMenuItem(
                        "Search",
                        "Pages.SleepCharts.Search",
                        "fa fa-search",
                        "/app/pcp/sleep-tracking"
                    ),
                    new AppMenuItem(
                        "Create",
                        "Pages.SleepCharts.Create",
                        "flaticon2-add-1",
                        "/app/pcp/create-edit-sleep-tracking/0/txt"
                    ),
                    new AppMenuItem(
                        "Settings",
                        "Pages.SleepCharts.Settings",
                        "flaticon-settings",
                        "/app/pcp/sleep-chart-settings"
                    ),
                ]
            ),

            new AppMenuItem(
                "Individual Specific Training",
                "",
                "flaticon2-line-chart",
                "",
                [
                    new AppMenuItem(
                        "Search",
                        "Pages.IndividualSpecificTrainings",
                        "fa fa-search",
                        "/app/hrst_ist/individualSpecificTrainings/individualSpecificTrainings"
                    ),
                    new AppMenuItem(
                        "Create",
                        "Pages.IndividualSpecificTrainings.Create",
                        "flaticon2-add-1",
                        "/app/hrst_ist/individualSpecificTrainings/create-or-edit-individualSpecificTraining-modal/0/txt"
                    ),
                ]
            ),
            new AppMenuItem("Document Center", "", "far fa-folder", "", [
                new AppMenuItem(
                    "Search",
                    "Pages.cpdocs",
                    "fa fa-search",
                    "/app/client/patients/upload/individualCharts/list/0"
                ),
                new AppMenuItem(
                    "Upload",
                    "Pages.cpdocs.Create",
                    "fa fa-upload",
                    "/app/client/patients/upload/individualCharts/upload/0"
                ),
            ]),

            new AppMenuItem("Nursing", "", "fas fa-stethoscope", "", [
                new AppMenuItem("Nursing Assessment", "", "la la-edit", "", [
                    new AppMenuItem(
                        "Search",
                        "Pages.NursingAssessments",
                        "fa fa-search",
                        "/app/assessment/nursingAssessments/nursingAssessments"
                    ),
                    new AppMenuItem(
                        "Create",
                        "Pages.NursingAssessments.Create",
                        "flaticon2-add-1",
                        "/app/assessment/nursingAssessments/create-or-edit-nursingAssessment-modal/0/txt"
                    ),
                ]),

                new AppMenuItem(
                    "Nursing Care Plan",
                    "",
                    "fa fa-street-view",
                    "",
                    [
                        new AppMenuItem(
                            "Search",
                            "Pages.NursingCarePlans",
                            "fa fa-search",
                            "/app/nursing/nursingCarePlan/nursingCarePlans"
                        ),
                        new AppMenuItem(
                            "Create",
                            "Pages.NursingCarePlans.Create",
                            "flaticon2-add-1",
                            "/app/nursing/nursingCarePlan/create-or-edit-nursingCarePlan-modal/0/0/txt"
                        ),
                    ]
                ),

                new AppMenuItem(
                    "Health Screening & Nursing Needs",
                    "",
                    "fa fa-life-ring",
                    "",
                    [
                        new AppMenuItem(
                            "Search",
                            "Pages.HRSTNursingNeeds",
                            "fa fa-search",
                            "/app/hrst_ist/hrstNursingNeeds/hrstNursingNeeds"
                        ),
                        new AppMenuItem(
                            "Create",
                            "Pages.HRSTNursingNeeds.Create",
                            "flaticon2-add-1",
                            "/app/hrst_ist/hrstNursingNeeds/create-or-edit-hrstNursingNeed-modal/0/txt"
                        ),
                    ]
                ),

                new AppMenuItem(
                    "Nurse Report To Teams",
                    "",
                    "fa fa-bars fa-lg",
                    "",
                    [
                        new AppMenuItem(
                            "Search",
                            "Pages.NurseReportToTeams",
                            "fa fa-search",
                            "/app/nursing/nurseReportToTeams/nurseReportToTeams"
                        ),
                        new AppMenuItem(
                            "Create",
                            "Pages.NurseReportToTeams.Create",
                            "flaticon2-add-1",
                            "/app/nursing/nurseReportToTeams/create-or-edit-nurseReportToTeam-modal/0/txt"
                        ),
                    ]
                ),

                new AppMenuItem(
                    "Nursing Diary",
                    "",
                    "flaticon-interface-3",
                    "",
                    [
                        new AppMenuItem(
                            "Search",
                            "Pages.NursingDiaries",
                            "fa fa-search",
                            "/app/nursing/nursingDiaries/nursingDiaries"
                        ),
                        new AppMenuItem(
                            "Create",
                            "Pages.NursingDiaries.Create",
                            "flaticon2-add-1",
                            "/app/nursing/nursingDiaries/create-or-edit-nursingDiary-modal/0/txt"
                        ),
                    ]
                ),
                new AppMenuItem(
                    "Med Admin Screening Tool",
                    "",
                    "fas fa-briefcase-medical",
                    "",
                    [
                        new AppMenuItem(
                            "Search",
                            "Pages.MedAdminScreeningTools",
                            "fa fa-search",
                            "/app/medadmin/medAdminScreeningTools/medAdminScreeningTools"
                        ),
                        new AppMenuItem(
                            "Create",
                            "Pages.MedAdminScreeningTools.Create",
                            "fa fa-plus",
                            "/app/medadmin/medAdminScreeningTools/create-or-edit-medAdminScreeningTool-modal/0/txt"
                        ),
                    ]
                ),
            ]),
            new AppMenuItem("Nutrition", "", "fas fa-leaf", "", [
                //new AppMenuItem('Choking Risk Screening Tool', '', 'fa fa-podcast', '', [
                //    new AppMenuItem('Search', 'Pages.ChokingRiskTools', 'fa fa-search', '/app/crst/chokingRiskTools/chokingRiskTools'),
                //    new AppMenuItem('Create', 'Pages.ChokingRiskTools.Create', 'flaticon2-add-1', '/app/crst/chokingRiskTools/create-or-edit-chokingRiskTool-modal/0/txt'),
                //]), removed on 10/10/2023 on Karen Booth's request with Robert's permission

                new AppMenuItem(
                    "Nutrition Assessment",
                    "",
                    "fa fa-medkit",
                    "",
                    [
                        new AppMenuItem(
                            "Search",
                            "Pages.NursingNutritions",
                            "fa fa-search",
                            "/app/nutrition/nursingNutritions/nursingNutritions"
                        ),
                        new AppMenuItem(
                            "Create",
                            "Pages.NursingNutritions.Create",
                            "flaticon2-add-1",
                            "/app/nutrition/nursingNutritions/create-or-edit-nursingNutrition-modal/0/txt"
                        ),
                    ]
                ),

                new AppMenuItem(
                    "Nutrition Diary",
                    "",
                    "la la-balance-scale",
                    "",
                    [
                        new AppMenuItem(
                            "Search",
                            "Pages.NutritionDiaries",
                            "fa fa-search",
                            "/app/nutrition/nutritionDiaries/nutritionDiaries"
                        ),
                        new AppMenuItem(
                            "Create",
                            "Pages.NutritionDiaries.Create",
                            "flaticon2-add-1",
                            "/app/nutrition/nutritionDiaries/create-or-edit-nutritionDiary-modal/0/txt"
                        ),
                    ]
                ),
            ]),

            new AppMenuItem("Behavioral", "", "fab fa-gg", "", [
                new AppMenuItem("ABC", "", "fa fa-arrows-alt", "", [
                    new AppMenuItem(
                        "Search",
                        "Pages.abc",
                        "fa fa-search",
                        "/app/behavioral/behavioral/abCs"
                    ),
                    new AppMenuItem(
                        "Create",
                        "Pages.abc.Create",
                        "flaticon2-add-1",
                        "/app/behavioral/behavioral/create-or-edit-abc-modal/0/txt"
                    ),
                ]),
                new AppMenuItem(
                    "Behavioral Consultation",
                    "",
                    "fa fa-indent",
                    "",
                    [
                        new AppMenuItem(
                            "Search",
                            "Pages.behavioralConsultation",
                            "fa fa-search",
                            "/app/behavioral/behavioral/behavioralConsultations"
                        ),
                        new AppMenuItem(
                            "Create",
                            "Pages.behavioralConsultation.Create",
                            "flaticon2-add-1",
                            "/app/behavioral/behavioral/create-or-edit-behavioralConsultation-modal/0/txt"
                        ),
                    ]
                ),
                new AppMenuItem("BSIS", "", "fa fa-life-ring", "", [
                    new AppMenuItem(
                        "Search",
                        "Pages.BSISs",
                        "fa fa-search",
                        "/app/behavioral/behavioral/bsiSs"
                    ),
                    new AppMenuItem(
                        "Create",
                        "Pages.BSISs.Create",
                        "flaticon2-add-1",
                        "/app/behavioral/behavioral/create-or-edit-bsis-modal/0/txt"
                    ),
                ]),
                new AppMenuItem(
                    "Behavioral Diary",
                    "",
                    "flaticon-interface-3",
                    "",
                    [
                        new AppMenuItem(
                            "Search",
                            "Pages.BehavioralDiaries",
                            "fa fa-search",
                            "/app/behavioral/behavioraldiary/behavioralDiaries"
                        ),
                        new AppMenuItem(
                            "Create",
                            "Pages.BehavioralDiaries.Create",
                            "flaticon2-add-1",
                            "/app/behavioral/behavioraldiary/create-or-edit-behavioralDiary-modal/0/txt"
                        ),
                    ]
                ),
            ]),

            new AppMenuItem(
                "Behavior Plans",
                "",
                "fas fa-envelope-open-text",
                "",
                [
                    new AppMenuItem(
                        "Search",
                        "Pages.ChokingRiskTools",
                        "fa fa-search",
                        "/app/behaviorPlan/behaviorPlans/behaviorPlans"
                    ),
                    new AppMenuItem(
                        "Create",
                        "",
                        "flaticon2-add-1",
                        "/app/behaviorPlan/behaviorPlans/create-or-edit-behaviorPlans/0/txt"
                    ),
                ]
            ),

            new AppMenuItem(
                "Choking Risk Screening Tool",
                "",
                "fa fa-podcast",
                "",
                [
                    new AppMenuItem(
                        "Search",
                        "Pages.ChokingRiskTools",
                        "fa fa-search",
                        "/app/crst/chokingRiskTools/chokingRiskTools"
                    ),
                    new AppMenuItem(
                        "Create",
                        "Pages.ChokingRiskTools.Create",
                        "flaticon2-add-1",
                        "/app/crst/chokingRiskTools/create-or-edit-chokingRiskTool-modal/0/txt"
                    ),
                ]
            ),

            new AppMenuItem(
                "StandingCommiteeMeetings",
                "",
                "flaticon2-writing",
                "",
                [
                    new AppMenuItem(
                        "Search",
                        "Pages.StandingCommiteeMeetings",
                        "fa fa-search",
                        "/app/meeting/standingCommitee/standingCommiteeMeetings"
                    ),
                    new AppMenuItem(
                        "Create",
                        "Pages.StandingCommiteeMeetings.Create",
                        "flaticon2-add-1",
                        "/app/meeting/standingCommitee/create-or-edit-standingCommiteeMeeting-modal/0"
                    ),
                ]
            ),

            new AppMenuItem(
                "Incident Report",
                "",
                "fa fa-exclamation-triangle",
                "",
                [
                    new AppMenuItem(
                        "Search",
                        "Pages.IncidentReports",
                        "fa fa-search",
                        "/app/incident/incidentReports/incidentReports"
                    ),
                    new AppMenuItem(
                        "Create",
                        "Pages.IncidentReports.Create",
                        "flaticon2-add-1",
                        "/app/incident/incidentReports/create-or-edit-incidentReport-modal/0/txt"
                    ),
                ]
            ),
            new AppMenuItem("Fire Drill", "", "fa fa-fire-extinguisher ", "", [
                new AppMenuItem(
                    "Search",
                    "Pages.FireDrills",
                    "fa fa-search",
                    "/app/fireDrills/fireDrills/fireDrills"
                ),
                new AppMenuItem(
                    "Create",
                    "Pages.FireDrills.Create",
                    "flaticon2-add-1",
                    "/app/fireDrills/fireDrills/create-or-edit-fireDrill-modal/0/txt"
                ),
            ]),

            new AppMenuItem("Vehicle Maintenance", "", "fa fa-car ", "", [
                new AppMenuItem(
                    "Search",
                    "Pages.VehicalManagements",
                    "fa fa-search",
                    "/app/vehicalManagements/vehicalManagements/vehicalManagements"
                ),
                new AppMenuItem(
                    "Create",
                    "Pages.VehicalManagements.Create",
                    "flaticon2-add-1",
                    "/app/vehicalManagements/vehicalManagements/create-or-edit-vehicalManagement-modal/0/txt"
                ),
            ]),

            new AppMenuItem(
                "Announcement",
                "Pages.Announcements",
                "fa fa-bullhorn",
                "/app/main/announcements/announcements"
            ),

            new AppMenuItem(
                "Activities",
                "Pages.Activities",
                "flaticon-more",
                "/app/main/masters/activities"
            ),

            //new AppMenuItem('Covid Consents', 'Pages.Consents', 'fas fa-circle', '/app/main/covidConsent/consents'),

            new AppMenuItem("HR", "", "fas fa-users ", "", [
                new AppMenuItem(
                    "Employee Evaluation",
                    "",
                    "far fa-list-alt",
                    "/app/hr/eval/list"
                ),
            ]),

            //new AppMenuItem('Service Notes Demo', '', 'far fa-sticky-note', '', [
            //    new AppMenuItem('Create', '', 'flaticon2-add-1', '/app/client/servicenoteDemo/create-edit-service-notesdemo/0/new'),
            //]),

            new AppMenuItem("Administration", "", "flaticon-interface-8", "", [
                new AppMenuItem(
                    "Document Type",
                    "",
                    "fa flaticon-folder-1",
                    "",
                    [
                        new AppMenuItem(
                            "Search",
                            "Pages.Documenttypes",
                            "fa fa-search",
                            "/app/masters/documenttype/documenttypes"
                        ),
                        new AppMenuItem(
                            "Create",
                            "Pages.Documenttypes.Create",
                            "flaticon2-add-1",
                            "/app/masters/documenttype/create-or-edit-documenttype-modal/0"
                        ),
                    ]
                ),
                //new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem(
                    "Roles",
                    "Pages.Administration.Roles",
                    "flaticon-suitcase",
                    "/app/admin/roles"
                ),
                new AppMenuItem(
                    "Users",
                    "Pages.Administration.Users",
                    "flaticon-users",
                    "/app/admin/users"
                ),

                new AppMenuItem(
                    "Sites",
                    "Pages.Sites",
                    "fa fa-map-marker",
                    "/app/masters/site/sites"
                ),
                new AppMenuItem(
                    "Vehicles",
                    "Pages.VehicleMasters",
                    "fa fa-car",
                    "/app/main/vehicleMasters/vehicleMasters"
                ),
                new AppMenuItem(
                    "Day Programs",
                    "Pages.Vocationalgroups",
                    "fa fa-life-ring ",
                    "/app/main/vocationalgroups/vocationalgroups"
                ) /*'Pages.Vocationalgroups'*/,
                new AppMenuItem(
                    "Program-Departments",
                    "Pages.Programs",
                    "fa fa-tasks",
                    "/app/masters/programDepartment/programDepartment"
                ),
                new AppMenuItem(
                    "Languages",
                    "Pages.Administration.Languages",
                    "flaticon-tabs",
                    "/app/admin/languages"
                ),
                new AppMenuItem(
                    "AuditLogs",
                    "Pages.Administration.AuditLogs",
                    "flaticon-folder-1",
                    "/app/admin/auditLogs"
                ),
                //new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem(
                    "Settings",
                    "Pages.Administration.Host.Settings",
                    "flaticon-settings",
                    "/app/admin/hostSettings"
                ),
                new AppMenuItem(
                    "Settings",
                    "Pages.Administration.Tenant.Settings",
                    "flaticon-settings",
                    "/app/admin/tenantSettings"
                ),

                new AppMenuItem(
                    "SIP Upload (Adult)",
                    "Pages.Administration.SipAdultUploades",
                    "fas fa-user-tie",
                    "/app/client/sipupload/sip-upload"
                ),
                new AppMenuItem(
                    "SIP Upload (Youth)",
                    "Pages.Administration.SipYouthUploades",
                    "flaticon-user",
                    "/app/client/SipYouthUpload/sip-youth-upload"
                ),
            ]),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];
            if (
                subMenuItem.permissionName === "" ||
                subMenuItem.permissionName === null ||
                (subMenuItem.permissionName &&
                    this._permissionCheckerService.isGranted(
                        subMenuItem.permissionName
                    ))
            ) {
                if (
                    subMenuItem.permissionName === "" ||
                    subMenuItem.permissionName === null
                ) {
                    if (subMenuItem.route) {
                        return true;
                    }
                } else if (
                    this._permissionCheckerService.isGranted(
                        subMenuItem.permissionName
                    )
                ) {
                    return true;
                } else if (subMenuItem.items && subMenuItem.items.length) {
                    return this.checkChildMenuItemPermission(subMenuItem);
                }
            }
            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive =
                    this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
        //for (let i = 0; i < menuItem.items.length; i++) {
        //    let subMenuItem = menuItem.items[i];

        //    if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null || subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
        //        return true;
        //    } else if (subMenuItem.items && subMenuItem.items.length) {
        //        return this.checkChildMenuItemPermission(subMenuItem);
        //    }
        //}

        //return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName ===
                "Pages.Administration.Tenant.SubscriptionManagement" &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (
            menuItem.permissionName &&
            !this._permissionCheckerService.isGranted(menuItem.permissionName)
        ) {
            hideMenuItem = true;
        }

        if (
            this._appSessionService.tenant ||
            !abp.multiTenancy.ignoreFeatureCheckForHostUsers
        ) {
            if (
                menuItem.hasFeatureDependency() &&
                !menuItem.featureDependencySatisfied()
            ) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
